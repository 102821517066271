import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Manager',
    component: () => import('../views/Manager.vue'),
    redirect: '/chat',
    children: [
      { path: 'chat', name: 'Chat', meta: { name: '智能助手' }, component: () => import('../views/manager/Chat.vue') },
      { path: 'mindmap', name: 'MindMap', meta: { name: '思维导图' }, component: () => import('../views/manager/Home.vue') },
      { path: 'about', name: 'About', meta: { name: '关于' }, component: () => import('../views/manager/About.vue') }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router